$( document ).ready(function() {

	// Fancybox
	$('.fancybox').fancybox();


	// Mobile menu
	menu = $('section.mobile-menu');
	$('a.toggle-mobile-menu').on('click', function(){
		if( $(menu).hasClass('hidden') ) {
			$(menu).removeClass('hidden');
		} else {
			$(menu).addClass('hidden');
		}
	});


	// Calculate iframe heigt
	$('.video-wrapper').height( $('.video-wrapper').width() * .69858 );


	// Header
	var owlHeader = $("section.header ul.slider"),
		options = {
			items: 1,
			mouseDrag: false,
			touchDrag: false,
			autoplay: true,
			autoplayTimeout: 3000,
			loop: true,
			//nav: true,
			navText: []
			};

	// Init
	owlHeader.owlCarousel( options );

	var optionsBig = {
			items: 1,
			mouseDrag: false,
			touchDrag: true,
			loop: true,
			nav: true,
			navText: []
			},
		optionsPlans = {
			items: 1,
			mouseDrag: false,
			touchDrag: true,
			dotData: true,
			loop: true,
			nav: true,
			dots: true,
			navText: []
			},
		optionsSmall = {
			items: 1,
			mouseDrag: true,
			touchDrag: true,
			loop: true,
		};

	// Init
	$('section.slider-section ul.slider-big').owlCarousel( optionsBig );

	$('section.hotel ul.slider-small').owlCarousel( optionsSmall );

	$('section.plans ul.slider').owlCarousel( optionsPlans );


	// Validate
	$( function(){
		$( '#header-form' ).validate({
			errorElement: "span"
		});
		$( '#callback-form' ).validate({
			errorElement: "span"
		});
		$( '#question-form' ).validate({
			errorElement: "span"
		});
		$( '#order-form' ).validate({
			errorElement: "span"
		});
		$( '#footer-contact-form' ).validate({
			errorElement: "span"
		});
	} );

});